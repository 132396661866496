import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Button from '@components/buttons';

const FallbackImage = (props) => (
  <img
    loading="lazy"
    {...props}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = '/static/empty.png';
    }}
  />
);

export const Anchor = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 4px;
  overflow: hidden;
`;

export const Head = styled(Anchor)`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 57%;
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Image = styled(FallbackImage)`
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.neutrals['light-gray']};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  color: #242424;
  font-size: 14px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #e8e8e8;
  border-top: none;
  padding: 15px 15px 0;
  margin-top: -1px;
  background-color: #fff;
  cursor: initial;
`;

export const Title = styled.p`
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
`;

export const Desc = styled.p`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  opacity: 0.8;
`;

export const Instructors = styled.p`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 17px;
  margin: 9px 0 6px;

  a {
    color: #129edc;
    text-decoration: none;
  }

  a,
  button {
    padding: 0;
  }

  .mx-2 {
    margin: 0 5px;
  }
`;

export const Tags = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
`;

export const Tag = styled.p<{ color?: string; sale?: boolean }>`
  font-size: 11px;
  color: ${({ color }) => color ?? '#fff'};
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px 0 0;
  background-color: ${({ sale }) => (sale ? '#cc206a' : '#fff')};

  :empty {
    display: none;
  }
`;

const FooterEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  padding: 19px 0;
  margin-top: 20px;
`;

export const Footer = ({ children, ...otherProps }) => {
  return <FooterEl {...otherProps}>{children}</FooterEl>;
};

export const Action = styled(Button)`
  display: flex;
  align-items: flex-start;
  color: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  padding: 0;
  background: none;

  :hover,
  :active {
    background: none;
  }

  :disabled {
    background: none;
  }
`;
