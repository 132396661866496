import styled from 'styled-components';
import { useRouter } from 'next/router';
import { parse } from 'date-fns/parse';
import { isValid } from 'date-fns/isValid';
import { isPast } from 'date-fns/isPast';
import { format } from 'date-fns/format';
import { Trans } from '@lingui/react';
import {
  Anchor,
  Card,
  Head,
  Body as body,
  ImageContainer,
  Image,
  Title,
  Desc,
  Tags,
  Tag,
} from '@ecosystems/search_page/cards/Elements';
import {
  BundleAccessStrategyKind,
  BundleCategoryKind,
  BundleEssentialFieldsFragment,
} from '@gql/generated';
import Icon from '@components/Icon';
import { Flex } from '@components/layout/Grid';
import { useFormatDuration } from '@lib/hooks/useFormatDuration';

const SidePanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #fff;
  text-align: center;
  padding: 0 10px;
  background-color: rgba(136, 191, 189, 0.9);

  i {
    margin-bottom: 5px;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-bottom: 6px;

    path {
      fill: white;
    }
  }
`;

const Description = styled(Desc)`
  display: -webkit-box;
  max-height: 3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Body = styled(body)`
  justify-content: space-between;
`;

const StyledCard = styled(Card)<{ expired?: boolean }>`
  flex: 1;
  height: 100%;
  width: inherit;
  border: none;
  cursor: pointer;
  ${({ expired }) => (expired ? 'opacity: 0.7;' : '')}
  transform: scale(0.99);
  transition: transform 250ms ease-out;

  :hover {
    transform: scale(1);
  }

  ${Body} {
    flex: 1;
    padding: 24px 15px 15px;
    transition: background-color 0.15s ease-in-out;
  }

  ${Head} {
    overflow: hidden;
  }
`;

export const ExpiredTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.secondary['red-1']};
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const OldPrice = styled(Price)`
  font-weight: 500;
  text-decoration: line-through;
  margin-left: 10px;
`;

const AccessStrategy = styled.span`
  font-weight: 500;
`;

const BundleStrategy = (props: {
  strategy: BundleAccessStrategyKind;
  category: BundleCategoryKind;
}) => {
  let text: React.ReactNode = '';

  if (
    props.category === BundleCategoryKind['ContentHub'] ||
    props?.strategy === BundleAccessStrategyKind['ForUsersWithAccess']
  ) {
    text = <Trans id="bundle.card.access_by_subscription" />;
  } else if (
    [BundleAccessStrategyKind['ForPremiumOnly']].includes(props?.strategy)
  ) {
    text = <Trans id="bundle.card.access_premium" />;
  } else if (props?.strategy === BundleAccessStrategyKind['ForAll']) {
    text = <Trans id="bundle.card.access_for_all" />;
  } else if (props?.strategy === BundleAccessStrategyKind['ByCode']) {
    text = <Trans id="bundle.card.access_by_code" />;
  }

  return <AccessStrategy>{text}</AccessStrategy>;
};

const BundleInfo = ({ infoText }) => {
  return (
    <SidePanel>
      <Icon name="ygb-icon-program-ic" fontSize="32px" color="white" />
      <span>{infoText}</span>
    </SidePanel>
  );
};

type Props = {
  className?: string;
  bundle: BundleEssentialFieldsFragment;
};

const BundleCard = (props: Props) => {
  const { className, bundle } = props;
  const clientSlug = bundle?.client?.slug;
  const router = useRouter();
  const lang = router.query.lang;
  const formatDuration = useFormatDuration();
  //
  let href = `/${lang}/c/${clientSlug}/b/${bundle.slug}`;

  const tags = [];
  let infoText = null,
    expired = false;
  try {
    if (bundle?.waitingItemInfo?.waitingListJoined) {
      let startDate = bundle?.waitingItemInfo?.startDate;
      startDate = parse(startDate, 'yyyy-MM-dd', new Date());
      if (isPast(startDate)) {
        infoText = <Trans id="bundle.dashboard_card.info_panel.get_access" />;
        href += '/get-started';
      } else {
        startDate = format(startDate, 'yyyy/MM/dd');
        infoText = (
          <Trans
            id="bundle.dashboard_card.info_panel.bundle_start_date"
            values={{ date: startDate }}
          />
        );
      }
    } else {
      const accessUntil = new Date(bundle?.userAccess?.accessUntil);
      if (isValid(accessUntil)) {
        const formatedAccessUntil = format(accessUntil, 'dd MMM yyyy');
        if (isPast(accessUntil)) {
          tags.push(
            <ExpiredTag key="0">
              <Trans id="bundle.dashboard_card.access_expired" />
            </ExpiredTag>
          );
        } else {
          tags.push(
            <Tag key="1" color="#242424">
              <Trans id="bundle.dashboard_card.info_panel.you_have_access" />
            </Tag>
          );
        }
        infoText = formatedAccessUntil;
      }
    }
  } catch (ex) {
    //
  }

  const price = bundle?.price?.discountApplied
    ? bundle?.price?.discountedPrice
    : bundle?.price?.originalPrice;

  let access_strategy = null;

  if (bundle?.price) {
    access_strategy = (
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex>
          <Price>
            {price / 100} {bundle.price.currency}
          </Price>
          {bundle.price.discountApplied ? (
            <OldPrice>
              {bundle.price.originalPrice / 100} {bundle.price.currency}
            </OldPrice>
          ) : null}
        </Flex>
        <span>
          {formatDuration({
            [bundle.accessPeriodUnit]: bundle.accessPeriodValue,
          })}
        </span>
      </Flex>
    );
  } else if (bundle?.accessStrategy) {
    access_strategy = (
      <Flex justifyContent="space-between" alignItems="flex-end">
        <BundleStrategy
          strategy={bundle?.accessStrategy}
          category={bundle?.bundleCategory}
        />
      </Flex>
    );
  }

  return (
    <Anchor href={`/${lang}/c/${clientSlug}/b/${bundle.slug}`}>
      <StyledCard className={className} expired={expired}>
        <Head as="div">
          <ImageContainer>
            <Image
              src={bundle?.cardImage ? bundle?.cardImage : bundle.heroImage}
              alt={`${bundle.title} thumbnail`}
            />
          </ImageContainer>
          {infoText ? <BundleInfo infoText={infoText} /> : null}
          {tags?.length > 0 ? <Tags>{tags}</Tags> : null}
        </Head>
        <Body>
          <div>
            <Title>{bundle.title}</Title>
            <Description>{bundle.shortDescription}</Description>
          </div>
          {access_strategy}
        </Body>
      </StyledCard>
    </Anchor>
  );
};

export default BundleCard;
