import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Container, Flex } from '@components/layout/Grid';
import { PageSection, SlugTypeKind, StartOverviewQuery } from '@gql/generated';
import LanguageContext from '@lib/contexts/languageContext';
import { Title as title } from '@ecosystems/landing_pages/hero/components';

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    flex-direction: row;
  }
`;

const Title = styled(title)`
  max-width: 620px;
  color: #243240;
  text-align: center;

  :empty {
    display: none;
  }
`;

const GridContainer = styled.div`
  width: 100%;
`;

const Col = styled.div`
  position: relative;
  height: 140px;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    height: 200px;
  }
`;

const Anchor = styled(Link)`
  flex: 1;
  text-decoration: none;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
  transform: scale(1);
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.02);
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    border-radius: 30px;
  }
`;

const ColContent = styled.div<{ image }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 14px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
`;

const ColTitle = styled.h3`
  font-size: clamp(16px, 2vw, 20px);
  font-weight: bold;
  line-height: 1;
  margin: 0 0 10px;
`;

const ColDesc = styled.p`
  max-width: 260px;
  font-size: clamp(13px, 1.6vw, 16px);
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
`;

const Column = ({
  className = '',
  href,
  title,
  desc,
  image = '',
  ...otherProps
}) => {
  return (
    <Anchor
      className={className}
      href={href}
      data-type="video-genre"
      data-id={otherProps['data-id']}
      data-title={otherProps['data-id']}
    >
      <Col>
        <ColContent image={image}>
          <ColTitle>{title}</ColTitle>
          <ColDesc>{desc}</ColDesc>
        </ColContent>
      </Col>
    </Anchor>
  );
};

const BigColumn = styled(Column)`
  color: #549dc7;
  background-color: #d8e5ed;
  margin-top: 10px;
`;

const SmallColumn1 = styled(Column)`
  color: #975780;
  background-color: #e7d4e1;
`;
const SmallColumn2 = styled(Column)`
  color: #5d9c97;
  background-color: #e0edee;
`;
const SmallColumn3 = styled(Column)`
  color: #667c91;
  background-color: #e2e7ed;
`;
const SmallColumn4 = styled(Column)`
  color: #a48843;
  background-color: #f3eedf;
`;

type Props = {
  data: {
    title?: string;
    description?: string;
    genre: StartOverviewQuery['startOverview']['genresSection']['genre'];
  };
};

type FormatedGenres = Record<SlugTypeKind, PageSection>;

export const VideoGenresSection = (props: Props) => {
  const lang = useContext(LanguageContext);
  const {
    data: { title, genre },
  } = props;

  const genres = useMemo<FormatedGenres>(() => {
    return genre.reduce((acc, item) => {
      acc[item.slug] = item;
      return acc;
    }, {}) as FormatedGenres;
  }, [genre]);

  return (
    <Container flexDirection="column" alignItems="center">
      <Title as="h2">{title}</Title>
      <GridContainer>
        <Grid>
          <BigColumn
            href={`/${lang}/videos/genres/yoga`}
            title={genres?.yoga?.title}
            desc={genres?.yoga?.description}
            image={genres?.yoga?.imageUrl}
            data-id="yoga"
          />
          <Flex flex={1} mt={10}>
            <SmallColumn1
              href={`/${lang}/videos/genres/meditation`}
              title={genres?.meditate?.title}
              desc={genres?.meditate?.description}
              image={genres?.meditate?.imageUrl}
              data-id="meditate"
            />
            <SmallColumn2
              href={`/${lang}/videos/genres/breathing`}
              title={genres?.breath?.title}
              desc={genres?.breath?.description}
              image={genres?.breath?.imageUrl}
              data-id="breath"
            />
          </Flex>
          <Flex flex={1} mt={10}>
            <SmallColumn3
              href={`/${lang}/videos/genres/workout`}
              title={genres?.move?.title}
              desc={genres?.move?.description}
              image={genres?.move?.imageUrl}
              data-id="move"
            />
            <SmallColumn4
              href={`/${lang}/videos/genres/grow`}
              title={genres?.grow?.title}
              desc={genres?.grow?.description}
              image={genres?.grow?.imageUrl}
              data-id="grow"
            />
          </Flex>
        </Grid>
      </GridContainer>
    </Container>
  );
};
